<template>
  <div id="navbar" style="width: 100%;">
  <div class="navbar navbar-container d-flex content align-items-center" v-if="userData">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>
    <!-- Search -->
    <!-- Left Col -->
    <div class="search-result align-items-center flex-grow-1 d-lg-flex">
      <b-nav-item style="list-style-type: none" class="search-nav" >
        <b-form autocomplete="off" name="search">
          <b-input-group class="input-group-merge round w-50">
            <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" width="16" height="16" />
          </b-input-group-prepend>
            <b-form-input id="search-link"  @focus="show=true" @input="onSearch(searchQuery)" @keydown.enter.prevent="handleDown()" name="search-link" placeholder="Search" v-model="searchQuery" />
            <b-input-group-append is-text v-if="searchQuery.length > 0" class="pr-0">
              <feather-icon icon="XIcon" class="text-default" v-b-tooltip.hover.v-dark title="Clear Search" @click="CloseModel()"/>
            </b-input-group-append>
          </b-input-group>
        </b-form>
        <div class="search-down">
          <!-- <b-card-text v-if="searchResult.length > 0 && search_on == 'common' " class="mt-1 px-2">
          <h5>Search Results</h5>
          <b-list-group flush >
            <b-list-group flush>
              <b-list-group-item v-for="Link in searchResult" :key="Link.route" v-if="$can(Link.gate)"> <feather-icon icon="StarIcon" 
                :class="isStarred(Link) ? 'text-primary' : ''" :id="Link.route" style="margin-top:-5px;margin-right:5px;"/><a @click="goTo(Link.route)">{{Link.label}}</a></b-list-group-item>
            </b-list-group>
          </b-list-group>
        </b-card-text>
        <b-tabs v-if="searchQuery.length > 0 && search_on == 'common' " class="px-2 pb-1">
          <b-tab title="All" active>
            <b-row>
              <b-col lg="4" v-for="(LinkSet,LinkSetName) in QuickLinks" :key="LinkSetName">
                <h5 class="text-primary">{{LinkSetName}}</h5>
                <b-list-group flush>
                  <b-list-group-item v-for="(Link,k) in LinkSet" :key="Link.route" v-if="$can(Link.gate)"> <feather-icon icon="StarIcon" 
                    :class="isStarred(Link) ? 'text-primary' : ''" :id="Link.route" style="margin-top:-5px;margin-right:5px;" @click="starred(LinkSetName,k)"/><a @click="goTo(Link.route)">{{Link.label}}</a></b-list-group-item>
                </b-list-group>
              </b-col>
            </b-row>          
          </b-tab>
          <b-tab
            title="Starred"
          >
          <b-row>
            <b-col>
              <b-list-group flush>
                <b-list-group-item v-for="route in userData.starred_routes" :key="route.label"> <feather-icon class="text-primary" icon="StarIcon" style="margin-top:-5px;margin-right:5px;"  /> <a @click="goTo(route.route)" > {{route.label}}</a> </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>   
        </b-tab>
      </b-tabs> -->
      <ul v-if="searchResult.length > 0 && show" class="suggestions-list">
        <li 
        v-for="suggestion in searchResult" :key="suggestion.type" @click="search(searchQuery,suggestion.type)">
          {{ suggestion.text }}
        </li>
      </ul>
        </div>
      </b-nav-item>
    </div>
    
      <!-- End -->
    <!-- End Search -->
    <div class="ml-auto mr-2 d-none d-md-flex right-navaction">
      <b-nav-item class="mr-1 santa-btn" style="list-style-type: none" v-if="userData.secret_santa_game == 'true'">
        <b-avatar square style="background-color: transparent;" v-b-tooltip.hover.v-dark
        title="Secret Santa Program"
        :to="{ name: 'secret-santa' }"
  
        v-if="$can('secret_santa_access') || $can('Zircly Admin')"
      :src="require('@/assets/images/secret-santa/santa-btn.svg')"
      class="w-auto"
    />
      </b-nav-item><!-- 
      <b-nav-item style="list-style-type: none" @click="OpenDoc" class="d-flex align-items-center justify-content-center">
        <feather-icon size="21" icon="BookOpenIcon" stroke="#6E6B7B" v-b-tooltip.hover.v-dark
        title="Documentation" />
      </b-nav-item>
      
      <b-nav-item style="list-style-type: none" class="new-icon d-flex align-items-center justify-content-center">
        <feather-icon size="21" icon="MonitorIcon" stroke="#6E6B7B" v-b-tooltip.hover.v-dark v-b-modal.whats-new-model
          v-if="release_notes && turnBadgeOff >= new Date()"
          title="What's New"
        >
        </feather-icon>
        <feather-icon size="21" icon="MonitorIcon"  stroke="#6E6B7B" v-b-tooltip.hover.v-dark v-b-modal.whats-new-model
          v-else
          title="What's New"
        />
        <b-img-lazy :src="require('@/assets/images/icons/newicon.png')" alt="whats-new" width="18"/>
      </b-nav-item> -->
      <b-modal
        id="whats-new-model"
        hide-footer
        centered
        size="lg"
        title="What's New"
      >
        <div>
          <b-carousel
            id="whats_new"
            ref="whats_new"
            :interval="5000"
            controls
          >
            <b-carousel-slide v-for="feature in release_notes.features" :key="feature.name">
              <template #img>
                <b-card
                  :img-src="require('@/assets/images/whats_new/'+feature.img_src)"
                  img-bottom
                  img-alt="card img"
                  :title="feature.name"
                  class="mb-3"
                  @click="openImage(require('@/assets/images/whats_new/'+feature.img_src))"
                >
                  <b-card-text>
                    {{feature.description}}
                  </b-card-text>
                </b-card>
              </template>
            </b-carousel-slide>
          </b-carousel>
      
          <!-- programmatically  -->
          <!-- <div class="d-flex align-items-center justify-content-center mt-1">
            <b-button-group>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="prev"
              >
                Pre
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="start"
              >
                {{ status ? 'Start' : 'Pause' }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="next"
              >
                Next
              </b-button>
            </b-button-group>
          </div> -->
        </div>
      </b-modal>
    </div>
    <!-- <b-dropdown
      id="dropdown-1"
      variant="success"
      class="mr-1"
      v-if="
        $can('hire_request_create') ||
        $can('employee_create') ||
        $can('candidate_create') ||
        $can('checklist_create') ||
        $can('holiday_create') ||
        $can('shift_create') ||
        $can('vacancy_create')
      "
    >
      <template #button-content>
        <span class="mr-75 d-none d-md-inline-block">Add New</span>
        <span><feather-icon size="16" icon="PlusIcon" class="" /></span>
      </template>
      <b-dropdown-item
        :to="{ name: 'add-nhr' }"
        v-if="$can('hire_request_create')"
        >Hire Request</b-dropdown-item
      >
      <b-dropdown-item
        :to="{ name: 'add-employee' }"
        v-if="$can('employee_create')"
        >Employee</b-dropdown-item
      >
      <b-dropdown-item
        :to="{ name: 'add-candidate' }"
        v-if="$can('candidate_create')"
        >Candidate</b-dropdown-item
      >
      <b-dropdown-item
        :to="{ name: 'add-checklist' }"
        v-if="$can('checklist_create')"
        >Checklist</b-dropdown-item
      >
      <b-dropdown-item :to="{ name: 'add-job' }" v-if="$can('vacancy_create')"
        >Job Openings</b-dropdown-item
      >
      <b-dropdown-item :to="{ name: 'add-shift' }" v-if="$can('shift_create')"
        >Shift</b-dropdown-item
      >
      <b-dropdown-item
        :to="{ name: 'add-holiday' }"
        v-if="$can('holiday_create')"
        >Holiday</b-dropdown-item
      >
    </b-dropdown>
    <b-dropdown
      id="dropdown-2"
      variant="outline-success"
      class="mr-1"
      v-if="
        $can('compensatoryOffApplication_create') ||
        $can('regularization_create') ||
        $can('leave_application_create')
      "
    >
      <template #button-content>
        <span class="mr-75 d-none d-md-inline-block">Apply For</span>
        <span><feather-icon size="16" icon="FilePlusIcon" class="" /></span>
      </template>
      <b-dropdown-item
        :to="{ name: 'add-regularize-application' }"
        v-if="$can('regularization_create')"
        >Regularization</b-dropdown-item
      >
      <b-dropdown-item
        :to="{ name: 'add-leave-application' }"
        v-if="$can('leave_application_create')"
        >Leave</b-dropdown-item
      >
      <b-dropdown-item
        :to="{ name: 'add-compensatory-off-application' }"
        v-if="$can('compensatoryOffApplication_create')"
        >Comp Off</b-dropdown-item
      >
    </b-dropdown> -->
    <div class="user-login">
        <!-- <b-button v-b-modal.modal-1 pill v-show="!userData.last_swipe_type ||
                      userData.last_swipe_type === 'Out'
                      " variant="primary" class="mr-1 login-btn"
                      v-if="$can('swipe_access') && userData.hashid == profileData.hashid"> -->
                      <b-button @click="checkin" pill v-show="!userData.last_swipe_type ||
                      userData.last_swipe_type === 'Out'
                      " variant="primary" class="mr-1 login-btn">
                      <!-- <feather-icon icon="LogInIcon" class="mr-50" /> -->
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <g>
                          <path d="M4.66797 2.66666V13.3333L13.3346 7.99999L4.66797 2.66666Z" fill="#fff"
                            stroke="#ffff" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                        </g>
                        <defs>
                          <clipPath>
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <span class="align-middle"> Check In </span>
                    </b-button>
                    <!-- <b-button v-b-modal.modal-2 v-show="userData.last_swipe_type === 'In'
                      " variant="outline-danger" class="mr-1 logout-btn" pill
                      v-if="$can('swipe_access') && userData.hashid == profileData.hashid"> -->
                      <b-button @click="checkout" v-show="userData.last_swipe_type === 'In'
                      " variant="danger" class="mr-1 logout-btn" pill>
                      <!-- <feather-icon icon="LogInIcon" class="mr-50" /> -->
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <g>
                          <path d="M4.66797 2.66666V13.3333L13.3346 7.99999L4.66797 2.66666Z" fill="#fff"
                            stroke="#fff" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
                        </g>
                        <defs>
                          <clipPath>
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                      <span class="align-middle ml-50"> Check Out </span>
                    </b-button>
                    <!-- <b-button
                      v-if="$route.params.id && userData.hashid == profileData.hashid"
                        variant="primary"
                        class="ml-auto d-flex"
                        :to="{ name: 'profile' }"
                        pill
                      >
                        <feather-icon icon="EditIcon" class="mr-50" />
                        <span class="font-weight-bold d-none d-md-flex align-middle"
                          >Edit</span
                        >
                    </b-button> -->
      </div>
    <b-navbar-nav class="nav align-items-center">
      <b-nav-item style="list-style-type: none" class="new-icon d-flex align-items-center justify-content-center">
        <feather-icon v-if="!isModalOpen"  :badge="all_unread_count" badge-classes="bg-danger" class="text-body" icon="BellIcon" size="21" stroke="#667085"  v-b-toggle.notification-info @click="isModalOpen = true"/>
        <feather-icon  v-if="isModalOpen" :badge="all_unread_count" badge-classes="bg-danger" class="text-body" icon="BellIcon" size="21" stroke="#667085" fill="#000000" v-b-toggle.notification-info @click="isModalOpen = false"/>
      </b-nav-item>
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <!-- <p class="user-name font-weight-bolder mb-0">
              {{ userData.first_name }}
            </p> -->
            <!-- <span class="user-status"> {{ userData.designation }}</span> -->
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="userData.avatar"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item disabled
        >
        <b-badge variant="light-primary" v-if="userData.shift_details">
          <span class="mb-1">{{ userData.shift_details.name }}</span
            ><br />
            <span class="">
              ({{ userData.shift_details.session_1_start_from | formatTime }} -
              {{ userData.shift_details.session_2_end_at | formatTime }})
            </span>
        </b-badge>
        </b-dropdown-item>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{
          name: 'employee-profile',
          params: { id: userData.hashid },
        }"
        target='_blank'
        >
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>Profile</span>
        </b-dropdown-item>
        <!-- Mobile View -->
        <b-dropdown-item
          link-class="d-flex align-items-center d-md-none"
          style="list-style-type: none" @click="OpenDoc"
        >
          <feather-icon size="21" icon="BookOpenIcon" title="Documentation" class="mr-50" />
          <span>Documentation</span>
        </b-dropdown-item>
        <b-dropdown-item link-class="d-flex align-items-center d-md-none" v-b-modal.whats-new-model>
          <span class="new-icon mr-50">
            <feather-icon size="21" icon="MonitorIcon" v-if="release_notes && turnBadgeOff >= new Date()" title="What's New" class="mr-50" />
            <feather-icon size="21" icon="MonitorIcon" v-b-modal.whats-new-model v-else title="What's New"/>
            <b-img-lazy :src="require('@/assets/images/icons/newicon.png')" alt="whats-new" width="18"/>
          </span>
            <span>What's New</span>
        </b-dropdown-item>
        <!-- End Mobile View -->
        <b-dropdown-item v-if="$can('note_access')"
          link-class="d-flex align-items-center"
          :to="{ name: 'my-notes' }"
        >
          <feather-icon size="16" icon="ClipboardIcon" class="mr-50" />
          <span>Notes</span>
        </b-dropdown-item>
        <!-- <b-dropdown-item
          link-class="d-flex align-items-center" @click="skin = isDark ? 'light' : 'dark'"
        >
        <feather-icon
          size="16"
          :icon="`${isDark ? 'Sun' : 'Moon'}Icon`" class="mr-50" 
        />
          <span>Switch Theme</span>
        </b-dropdown-item> -->
        
        <!-- Secret Santa -->
        
        <b-dropdown-item
          link-class="d-flex align-items-center d-md-none pl-50 py-25 mobile-santa"
          style="list-style-type: none" v-if="userData.secret_santa_game == 'true'"
        >
        <div class="mr-1 santa-btn" style="list-style-type: none">
              <b-avatar square style="background-color: transparent;" v-b-tooltip.hover.v-dark
              title="Secret Santa Program"
              :to="{ name: 'secret-santa' }"
        
              v-if="$can('secret_santa_access') || $can('Zircly Admin')"
            :src="require('@/assets/images/secret-santa/santa-mobile.svg')"
            class="w-auto"
          />
        </div>
        </b-dropdown-item>
        <!-- End Secret Santa-->
        <!--<b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MailIcon"
            class="mr-50"
          />
          <span>Inbox</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="CheckSquareIcon"
            class="mr-50"
          />
          <span>Task</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MessageSquareIcon"
            class="mr-50"
          />
          <span>Chat</span>
        </b-dropdown-item>-->

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
    <b-modal
      id="search-options"
      ref="search-options"
      title="Search And Quick Links"
      :hide-footer='true'
      hide-backdrop
      size="lg"
      :modal-class="myclass"
      @close="CloseModel()"
    >
      
      <b-card-text v-if="searchResult.length > 0" class="mt-1">
        <h5>Search Results</h5>
        <b-list-group flush >
          <b-list-group flush>
            <b-list-group-item v-for="Link in searchResult" :key="Link.route" v-if="$can(Link.gate)"> <feather-icon icon="StarIcon" 
              :class="isStarred(Link) ? 'text-primary' : ''" :id="Link.route" style="margin-top:-5px;margin-right:5px;"/><a @click="goTo(Link.route)">{{Link.label}}</a></b-list-group-item>
          </b-list-group>
        </b-list-group>
      </b-card-text>
      <b-tabs>
        <b-tab title="All" active>
          <b-row>
            <b-col lg="4" v-for="(LinkSet,LinkSetName) in QuickLinks" :key="LinkSetName">
              <h5 class="text-primary">{{LinkSetName}}</h5>
              <b-list-group flush>
                <b-list-group-item v-for="(Link,k) in LinkSet" :key="Link.route" v-if="$can(Link.gate)"> <feather-icon icon="StarIcon" 
                  :class="isStarred(Link) ? 'text-primary' : ''" :id="Link.route" style="margin-top:-5px;margin-right:5px;" @click="starred(LinkSetName,k)"/><a @click="goTo(Link.route)">{{Link.label}}</a></b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>          
        </b-tab>
        <b-tab
          title="Starred"
        >
        <b-row>
          <b-col>
            <b-list-group flush>
              <b-list-group-item v-for="route in userData.starred_routes" :key="route.label"> <feather-icon class="text-primary" icon="StarIcon" style="margin-top:-5px;margin-right:5px;"  /> <a @click="goTo(route.route)" > {{route.label}}</a> </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>   
      </b-tab>
      </b-tabs>
    </b-modal>
    <!-- Notification Information -->
    <b-collapse id="notification-info" class="mt-2" v-if="isModalOpen">
  <b-tabs class="next-expand" lazy      
  >
    
       <!-- All Notifications Tab -->
    <b-tab @click="addFilter('all')" >
      <template #title>
        <span>All <b-badge class="nofication-count" variant="secondary">{{ all_unread_count || "" }}</b-badge></span>
      </template>
      <b-list-group v-if="isLoadingNotification">
        <p>Loading please wait</p>
      </b-list-group>
      <b-list-group 
      ref="div-scroll"
      v-infinite-scroll="loadMoreNotifications"
      :infinite-scroll-disabled="loading" 
      infinite-scroll-distance="1"
      v-else-if="notifications && notifications.length > 0"
      >
        <NotificationItem
          v-for="notification in notifications.filter(n => (n.additional_data && (n.additional_data.channel_type === 'community' || n.additional_data.channel_type === 'post')) || !n.additional_data)"
          :key="notification.hashid"
          :notification="notification"
          :userData="userData"
          :isUnread="notification.is_read"
          @markAsRead="markAsRead"
        />
      </b-list-group>
      <b-list-group  v-else>
      <p>No Data Found.</p>
  </b-list-group>
    </b-tab>

    <!-- Mentions Tab -->
    <b-tab @click="addFilter('mentions')">
  <template #title>
    <span>
      Mentions
      <b-badge class="notification-count" variant="secondary">{{ mention_unread_count || '' }}</b-badge>
    </span>
  </template>
  <b-list-group v-if="isLoadingNotification">
        <p>Loading please wait</p>
      </b-list-group>
  <b-list-group v-else-if="notifications && notifications.length > 0">
    <NotificationItem
      v-for="notification in notifications"
      :key="notification.hashid"
      :notification="notification"
      :isUnread="notification.is_read"
      :userData="userData"
    />
  </b-list-group>

  <b-list-group  v-else>
    <p>No mentions available.</p>
  </b-list-group>
     </b-tab>
    <!-- My Posts Tab -->
    <b-tab @click="addFilter('my-post')">
      <template #title>
        <span>My Posts <b-badge class="nofication-count" variant="secondary">{{ my_post_unread_count || "" }}</b-badge></span>
      </template>
      <b-list-group v-if="isLoadingNotification">
        <p>Loading please wait</p>
      </b-list-group>
      <b-list-group v-else-if="notifications && notifications.length > 0">
        <NotificationItem
          v-for="notification in notifications.filter(n => n.additional_data && n.additional_data.channel_type === 'community')"
          :key="notification.hashid"
          :notification="notification"
          :isUnread="notification.is_read"
          :userData="userData"
          @markAsRead="markAsRead"
        />
      </b-list-group>
      <b-list-group v-else>
     <p>No Data Found.</p>
  </b-list-group>
    </b-tab>
    <b-tab class="mark-read">
      <template #title>
        <span @click="markAllRead" v-b-tooltip.hover.v-default v-b-tooltip.top title="Mark as Read">
          <!-- <b-badge class="nofication-count" variant="secondary">{{ my_post_unread_count || "" }}</b-badge> -->
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clip-path="url(#clip0_14688_42315)">
              <path d="M7 12L12 17L22 7" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M2 12L7 17M12 12L17 7" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_14688_42315">
                <rect width="24" height="24" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          <!-- <b-badge class="nofication-count" variant="secondary">{{ my_post_unread_count || "" }}</b-badge>-->
        </span>
      </template>
    </b-tab>
  </b-tabs>
 
</b-collapse>


    <!-- End Notification Information -->
       <!-- swipe in -->
                    <b-modal id="modal-1" size="lg" @show="resetModal" @hidden="resetModal" @ok="handleOk" :hide-header="true"
                  :hide-footer="true" centered modal-class="attend-check">
                  <div v-if="'No' in userData.previous_day_attendance_status">
                    <p class="my-1 text-center mobile-text">
                      Regularization Required for last working day -
                      {{
                        userData.previous_day_attendance_status.No | formatDate
                      }}, Please Apply for Regularization for last working day
                    </p>
                  </div>
                  <form ref="form" @submit.stop.prevent="handleOk" class="attend-login">
                    <b-form-group label="" label-for="name-input" class="">
                      <h2 class="login-header my-2">
                        How are you feelinggg today?
                      </h2>
                      <b-form-radio-group buttons class="mx-n1 feeling-smiley" name="user_feeling"
                        v-model="swipeData.user_feeling" button-variant="transparent" @change="handleOk">
                        <b-form-radio v-for="option in options" :value="option.value" :key="option.text"
                          :class="`border-0 p-0 m-0 mood-btn`">
                          <!-- style="width: 50px" -->
                          <b-img fluid :src="option.img" alt="mood" class="unactive" />
                          <b-img fluid :src="option.gifimg" alt="mood" class="activegif" />
                          <h6>{{ option.text }}</h6>
                        </b-form-radio>
                      </b-form-radio-group>
                    </b-form-group>
                    <div class="skip-feeling mt-2 mb-1 pl-md-1 cursor-pointer"  @click="handleOk" >Skip</div>
                  </form>
                </b-modal>

       <!-- swipe out -->

              <b-modal id="modal-2" size="lg" @show="resetModal" @hidden="resetModal" @ok="checkout" :hide-header="true"
                  :hide-footer="true" centered modal-class="attend-check"
                   >
                  
                  <form ref="form" @submit.stop.prevent="checkout" class="attend-login">
                    <b-form-group label="" label-for="name-input" class="">
                      <h2 class="login-header my-2">
                        How you felt today?
                      </h2>
                      <b-form-radio-group buttons class="mx-n1 feeling-smiley" name="user_feeling"
                        v-model="swipeData.user_feeling" button-variant="transparent" @change="checkout">
                        <b-form-radio v-for="option in options" :value="option.value" :key="option.text"
                          :class="`border-0 p-0 m-0 mood-btn`">
                          <!-- style="width: 50px" -->
                          <b-img fluid :src="option.img" alt="mood" class="unactive" />
                          <b-img fluid :src="option.gifimg" alt="mood" class="activegif" />
                          <h6>{{ option.text }}</h6>
                        </b-form-radio>
                      </b-form-radio-group>
                    </b-form-group>
                    <div class="skip-feeling mt-2 mb-1 pl-md-1 cursor-pointer" @click="checkout" >Skip</div>
                  </form>
                </b-modal>
    <!-- New Attendance Design -->
    <b-modal id="swipe-in" :hide-header="true"
    :hide-footer="true" centered modal-class="attend-check new-attend">
        <div class="attend-login d-flex align-items-center justify-content-center text-center flex-column">
            <h2 class="my-1">Good Morning!</h2>
            <p class="attend-info mt-2 mb-3 px-2">Welcome back. Start your day strong by checking in and catching up with what's new in the DCKAP Community.</p>
            <b-button variant="primary" pill @click="checkin" lass="attend-btn m-auto">Check-In</b-button>
        </div>
    </b-modal>
    <b-modal id="swipe-out" :hide-header="true"
    :hide-footer="true" centered modal-class="attend-check new-attend">
        <div class="attend-login d-flex align-items-center justify-content-center text-center flex-column">
            <h2 class="my-1">Great Work Today!</h2>
            <p class="attend-info mt-2 mb-3 px-2">Another productive day done! Mark your check-out and take a moment to wrap up before logging off.</p>
            <b-button variant="danger" @click="checkout" pill class="attend-btn m-auto">Check-Out</b-button>
        </div>
    </b-modal>
  </div>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BNavItem,
  BCarousel,
  BCarouselSlide,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import { avatarText } from "@core/utils/filter";
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from "vue";
import VueCookies from "vue-cookies";
import store from "@/store";
import { bus } from "@/main.js";
import $ from 'jquery';
import axios from "@axios";
// import Toast from "vue-toastification";
// import "vue-toastification/dist/index.css";
// Vue.use(Toast, {
//   transition: "Vue-Toastification__bounce",
//   maxToasts: 20,
//   newestOnTop: true
// });
Vue.use(VueCookies);
import Ripple from 'vue-ripple-directive'

import whats_new from "@/assets/jsons/whats_new.json"

import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import NotificationItem from "./NotificationItem.vue";

export default {
  components: {
    NotificationItem,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BNavItem,

    // Navbar Components
    DarkToggler,
    BCarousel,
    BCarouselSlide,
    ToastificationContent
  },
  directives: {
    Ripple,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    return { skin, isDark }
  },
  data() {
    return {
      userData: this.$cookies.get("userData"),
      avatarText,
      isLoadingNotification:false,
      loading:false,
      notifications:[],
      filterType:"all",
      all_unread_count:0,
      mention_unread_count:0,
      my_post_unread_count:0,
      interval: null,
      time: null,
      employeeData:null,
      options: null,
      show:true,
      isModalOpen:false,
      myclass: ['myclass'],
      QuickLinks:{
        'Core Actions' : [
          {
            'label' : 'Add Employee',
            'route' : 'add-employee',
            'gate' : 'employee_create',
          },
          {
            'label' : 'Add Candidate',
            'route' : 'add-candidate',
            'gate' : 'candidate_create'
          },
          {
            'label' : 'Hire A Star(Refer)',
            'route' : 'my-referrals',
            'gate' : 'referral_access'
          },
          {
            'label' : 'Interviews',
            'route' : 'all-interviews',
            'gate' : 'interview_access'
          },
          {
            'label' : 'Meetings',
            'route' : 'meetings',
            'gate' : 'meeting_access'
          },
          {
            'label' : 'My Notes',
            'route' : 'my-notes',
            'gate' : 'note_access'
          },
          {
            'label': "Settings",
            'route': "company-settings",
            'gate': "settings_access"
          }
        ],
        'Apply/Review':[
          {
            'label' : 'Leave Applications',
            'route' : 'leave-applications',
            'gate' : 'leave_application_access'
          },
          {
            'label': "Comp Off Applications",
            'route': "compensatory-off-applications",
            'gate': "compensatoryOffApplication_access"
          },
          {
            'label': "Regularization",
            'route': "regularize-applications",
            'gate': "regularization_access"
          },
          {
            'label': "OKR",
            'route': "okrs",
            'gate': "okr_access"
          }
        ],
        'Others':[
          {
            'label': "Holidays",
            'route': "holiday-calendar",
            'gate': "holiday_show"
          },{
            'label': "My Attendance Info",
            'route': "attendance-info",
            'gate': "attendance_show"
          },{
            'label': "Email Notifications",
            'route': "email-subscription",
            'gate': "email_notification_subscription_edit"
          },
          {
            'label': "Create Suport Ticket",
            'route': "add-ticket",
            'gate': "ticket_access"
          },
        ]
      },
      // New update code
      options: [
        {
          text: "Energetic",
          value: "5",
          color: "btn-outline-danger",
          icon: "ThumbsDownIcon",
          img: require("@/assets/images/moods/cute.svg"),
          gifimg: require("@/assets/images/moods/energetic.gif"),
        },
        {
          text: "Focused",
          value: "4",
          color: "btn-outline-danger",
          icon: "ThumbsDownIcon",
          img: require("@/assets/images/moods/shy.svg"),
          gifimg: require("@/assets/images/moods/focused.gif"),
        },
        {
          text: "Neutral",
          value: "3",
          color: "btn-outline-primary",
          icon: "PauseIcon",
          img: require("@/assets/images/moods/neutral.svg"),
          gifimg: require("@/assets/images/moods/neutral.gif"),
        },
        {
          text: "Stressed",
          value: "2",
          color: "btn-outline-success",
          icon: "ThumbsUpIcon",
          img: require("@/assets/images/moods/thinking.svg"),
          gifimg: require("@/assets/images/moods/stressed.gif"),
        },
        {
          text: "Frustrated",
          value: "1",
          color: "btn-outline-success",
          icon: "ThumbsUpIcon",
          img: require("@/assets/images/moods/angry.svg"),
          gifimg: require("@/assets/images/moods/frustrated.gif"),
        },
      ],
      swipeData: {
        type: "",
        user_feeling: 6,
        browser_agent:"",
        device_type:""

      },
      recentSwipes: null,
      mood_options: {
        1: require("@/assets/images/moods/angry.svg"),
        2: require("@/assets/images/moods/thinking.svg"),
        3: require("@/assets/images/moods/neutral.svg"),
        4: require("@/assets/images/moods/shy.svg"),
        5: require("@/assets/images/moods/cute.svg"),
      },
      profileData: {
        header: {
          avatar: "",
          username: "",
          designation: "",
          coverImg: require("@/assets/images/profile/user-uploads/timeline.svg"),
        },
        userAbout: {
          employee: "",
          email: "",
          joined: "",
        },
      },
      // End New update code
      communityRoutes : ['home','draft-posts','scheduled-posts','saved-posts','community'],
      searchQuery:'',
      showSuggestions: false,
      searchResult:[],
      status: false,
      release_notes:[],
      turnBadgeOff:new Date('2023-04-05'),
      search_on:"common",
      mode: this.$cookies.get("userData") ? this.$cookies.get("userData").application_mode : 'Company'
    };
  },
  // mounted() {
  //   // Set up the listener for the postsLoaded event once
  //   bus.$on('postsLoaded', (posts) => {
  //     if (this.searchQuery.length > 0) {
  //       this.searchResult = posts;
  //       this.showSuggestions = this.searchResult.length > 0;
  //     }
  //     // Handle the posts data as needed
  //   });
  // },
  beforeDestroy() {
    // prevent memory leak
    // bus.$off('postsLoaded');
    clearInterval(this.interval);
  },
  mounted() {
    this.getNotification();
},

  created() {

    bus.$on("view-wish",(data) => {
            console.log(data,"ijijijijijij");
        });
    // update the time every second
      this.release_notes = whats_new;
      const urlParams = new URLSearchParams(window.location.search);
   

      bus.$on("search_on", (search_on = "common") => {
        this.search_on = search_on;

        
        if(urlParams.get('q')){
          this.searchQuery = urlParams.get('q');
          this.onSearch(this.searchQuery,false);
        }else{
          this.onSearch(this.searchQuery);
        }

      });

    this.interval = setInterval(this.webSocketsChannels(), 1000);
      
  },

  computed:{
   
  },
  watch: {
    // searchQuery(val) {
    //   this.updateQuries(val);    
    // }
  },
  methods: {
    removeImgTag(content) {
    return content.replace(/<img[^>]*>/g, ""); 
  },
  webSocketsChannels(){
      console.log(window.location.hostname+'.WebNotification.'+this.userData.hashid)
      window.Echo.private(window.location.hostname+'.WebNotification.'+this.userData.hashid)
        .listen('WebNotificationEvent', (data) => {
          console.log(data);

        console.log(this.notifications);
        this.notifications = [data.notification].concat(this.notifications);

        console.log(this.notifications);
      });
  },
  addFilter(type) {
    this.filterType = type
    this.getNotification()
  },
markAsRead(data) {
    var hashids = []
    hashids.push(data.hashid)
    this.isModalOpen = false;
    axios.post("notification/mark-as-read", { notification_ids : hashids})
      .then((response) => {
        if (response.data.success) {
         window.open(this.$router.resolve(data.link).href, '_blank');
          this.getNotification()
          this.$route(data.link)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification marked successfully',
              icon: 'UserCheckIcon',
              variant: 'success',
              text: response.data.message,
            },
          }, {
            timeout: 2000, 
            position: 'bottom-center',
            toastClassName: "radius-8 successful-info",
            hideProgressBar: true,
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification marked failed',
              icon: 'UserXIcon',
              variant: 'danger',
              text: response.data.message,
            },
          }, {
            timeout: 2000, 
            position: 'bottom-center',
            toastClassName: "radius-8 error-info",
            hideProgressBar: true,
          });
        }
      })
      .catch((error) => {
        console.error("Error marking notifications as read:", error);
      });
},
markAllRead() {
var hashids = []
 if (this.filterType) {
    this.notifications.forEach((item) => {
        if (!item.is_read) {
            hashids.push(item.hashid);
        }
    });
}
    axios.post("notification/mark-as-read", { notification_ids : hashids})
      .then((response) => {
        if (response.data.success) {
          this.getNotification()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'All Notification marked successfully',
              icon: 'UserCheckIcon',
              variant: 'success',
              text: response.data.message,
            },
          }, {
            timeout: 2000, 
            position: 'bottom-center',
            toastClassName: "radius-8 successful-info",
            hideProgressBar: true,
          });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'All Notification marked failed',
              icon: 'UserXIcon',
              variant: 'danger',
              text: response.data.message,
            },
          }, {
            timeout: 2000, 
            position: 'bottom-center',
            toastClassName: "radius-8 error-info",
            hideProgressBar: true,
          });
        }
      })
      .catch((error) => {
        console.error("Error marking notifications as read:", error);
      });
},
getNotification() {
  let params = {
  type: this.filterType
};
this.isLoadingNotification = true;
this.notifications = [];
  axios.get("notification/get-notification",{ params })
    .then((response) => {
      if (response.data.success) {
        this.notifications = response.data.notification
        this.isLoadingNotification = false
        this.all_unread_count = response.data.all_unread_count || 0;
        this.mention_unread_count = response.data.mention_unread_count || 0;
        this.my_post_unread_count = response.data.my_post_unread_count || 0;
      } else {
        console.error("Error fetching notifications");
        this.isLoadingNotification = false

      }
    })
    .catch((error) => {
      console.error("An error occurred:", error);
      this.isLoadingNotification = false

    });
},
loadMoreNotifications() {
  console.log("okok");
  
            // if (this.loading) return;
            // this.loading = true;
            // let params = {
            //     type: this.filterType,
            // };
            // axios
            //     .get("notification/get-notification", { params })
            //     .then((response) => {
            //         if (response.data.success) {
            //             this.notifications = [...this.notifications, ...response.data.notification];
            //         } else {
            //             console.error("Error fetching notifications");
            //         }
            //     })
            //     .catch((error) => {
            //         console.error("An error occurred:", error);
            //     });
        },

    resetModal() {
      this.swipeData.type = "";
      this.swipeData.user_feeling = 6;
    },
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      // bvModalEvent.preventDefault();
      // Trigger submit handler
      
      this.checkin();
    },
    handleDown(event) {
      let currentRoute = this.$route.name;
        if (this.communityRoutes.includes(currentRoute)) {
            const trimmedStr = this.searchQuery.trim();
            this.search(trimmedStr, "post");
        }else{
          // this.$toast({
          //     component: ToastificationContent,
          //     props: {
          //       title: "Choose Module to Search",
          //       icon: "SearchIcon",
          //       variant: "info",
          //       text: "Please choose module from options to search",
          //     },
          //   });

            this.$toast({
                            component: ToastificationContent,
                            props: {
                            title: 'Choose Module to Search',
                            icon: 'SearchIcon',
                            variant: 'info',
                            text: `Please choose module from options to search`,
                            },
                        }, {
                            timeout: 2000, 
                            position: 'bottom-center',
                            toastClassName:"radius-8 successful-info",
                            hideProgressBar : true,
                        });


            
        }
    },
    openImage(path){
      window.open(path, '_blank');
    },
    openURL(){
      var re = new RegExp(/^.*\//);
      window.open(re.exec(window.location.href)+"map-view", '_blank');
    },
    getSource(src){
      return require(src);
    },
    prev() {
      if (!this.status) {
        this.$refs.whats_thenew.prev()
      }
    },
    next() {
      if (!this.status) {
        this.$refs.whats_new.next()
      }
    },
    start() {
      this.status = !this.status
      if (this.status) {
        this.$refs.whats_new.pause()
      } else {
        this.$refs.whats_new.start()
      }
    },
    logout() {
  // Retrieve the access token from cookies
  const token = useJwt.getToken(); 

  if (!token) {
    console.error("Token not found");
    return; // Return early if no token is found
  }

  // Send the logout request to the server
  return axios.post(useJwt.jwtConfig.logoutEndpoint, null, {
    headers: {
      Authorization: `Bearer ${token}`, // Attach the token in the Authorization header
    },
  })
    .then((response) => {
      this.$cookies.remove('accessToken');
      localStorage.removeItem('userData');
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      this.$router.push({ name: "auth-login" });

      return response;
    })
    .catch((error) => {
      console.error("Logout error", error);
      return error;
    });
},

    // logout() {
    //   this.$cookies.remove("userData");
    //   this.$cookies.remove("accessToken");
    //   if (!useJwt.getToken()) {
    //     this.$router.push({ name: "auth-login" });
    //   }
    //   // Remove userData from localStorage
    //   useJwt.logout().then((res) => {
    //     localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
    //     // Remove userData from localStorage
    //     localStorage.removeItem("userData");
    //     localStorage.removeItem("hasRedirect");
    //     localStorage.removeItem("EncryptedAbilities");
    //     localStorage.removeItem('postListFilter');
    //     // localStorage.clear()
    //     // Reset ability
    //     this.$ability.update(initialAbility);
    //     // this.$toast({
    //     //   component: ToastificationContent,
    //     //   props: {
    //     //     title: "Sign Out",
    //     //     icon: "BellIcon",
    //     //     text: res.data.message,
    //     //     variant: "success",
    //     //   },
    //     // });



    //     this.$toast({
    //           component: ToastificationContent,
    //           props: {
    //             title: 'Sign Out',
    //             icon: 'BellIcon',
    //             variant: 'success',
    //             text: res.data.message,
    //           },
    //         }, {
    //           timeout: 2000, 
    //           position: 'bottom-center',
    //           toastClassName:"radius-8 successful-info",
    //           hideProgressBar : true,
    //         });
        
    //     // Redirect to login page
    //     this.$router.push({ name: "auth-login" });
    //   });
    // },
  
    OpenDoc(){
      window.open("https://docs.zircly.com", "_blank");
    },
    OpenModel(){
      this.$refs['search-options'].show();
      $('#search-link').focus();
      
    },
    goTo(route){
      // Redirect to page
      this.$router.push({ name:route });
    },
    starred(setName,key){
      var type = 'add';
      if ($( "#"+this.QuickLinks[setName][key].route).hasClass('text-primary')) {
        $(  "#"+this.QuickLinks[setName][key].route).removeClass( 'text-primary');
        type = 'remove';
      } else {
        $(  "#"+this.QuickLinks[setName][key].route).addClass( 'text-primary');
      }
      const self = this;
      const formData = new FormData();
      formData.append("starred", JSON.stringify(this.QuickLinks[setName][key]));
      let data = {};
      data.url = "starred-route/"+type;
      data.params = formData;
      this.$store
        .dispatch("app/store", data)
        .then((res) => {
          this.userData.starred_routes = res.data.starred_routes;
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Route "+type+"ed",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: res.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: "Route "+type+"ed",
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
        })
        .catch((error) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Unable to "+type+" the Route",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });

          this.$toast({
        component: ToastificationContent,
        props: {
          title: "Oops! Unable to "+type+" the Route",
          icon: 'BellIcon',
          variant: 'danger',
          text: error,
        },
      }, {
        timeout: 2000, 
        position: 'bottom-center',
        toastClassName:"radius-8 error-info ",
        hideProgressBar : true,
      });
        });
    },
    isStarred(link){
      if(this.userData.starred_routes.length > 0){
        for ( var key in this.userData.starred_routes) {
          if(this.userData.starred_routes[key].label == link.label){
            return true;
          }
        }
      }
      return false;
    },
    search(str, type) {
  const trimmedStr = str.trim();
  const queryParams = { q: trimmedStr, type: type };
  if (type == 'post') {
    bus.$emit("AddFilter",'community');
    
      this.$router.push({ 
        path: '/community',
        query: { 
          q: trimmedStr,
          type: "community"
        }
      });
      this.searchQuery  = trimmedStr;

  } else if (type == 'event') {
    bus.$emit("AddFilter",type);
    this.$router.push({ path: '/community', query: queryParams })
    .then(() => {
      bus.$emit("loadEvents", { q: trimmedStr, type: type });
    })
  } 
  else if (type == 'poll') {
    bus.$emit("AddFilter",type);
    bus.$emit("loadPolls",trimmedStr);
    this.$router.push({ path: '/community', query: queryParams });
  } 
  else if (type == 'employee') {
    this.$router.push({ path: '/employees', query: { q: trimmedStr } });
  } else if (type == 'okr') {
    this.$router.push({ path: '/list/all-okrs', query: { q: trimmedStr } });
  } else if (type == 'profile') {
    if (this.employeeData) {
                        window.open(
                            `/profile/${this.employeeData.hashid}`,
                            "_blank"
                        );
                    }
  } else if (type == 'member') {
    this.$router.push({ path: '/members', query: { q: trimmedStr } });
  } else if (type == 'candidate') {
    this.$router.push({ path: '/candidates', query: { q: trimmedStr } });
  }
  this.show = false;
},

    isValidEmail(email) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    },

    async onSearch(search_str,show = true){
      this.show = show;
      const query = search_str.trim();
      if (query.length === 0 ) {
        this.searchResult = [];
        return;
      }
      const baseSuggestions = [
        { text: `Search "${query}" in Post`, type: 'post' },
        { text: `Search "${query}" in Events`, type: 'event' },
        { text: `Search "${query}" in Poll`, type: 'poll' },
      ];

     
      if (this.mode === 'company' && !this.isValidEmail(query)) {
        this.searchResult = [
          ...baseSuggestions,
          { text: `Search "${query}" in Employee`, type: 'employee' },
          { text: `Search "${query}" in Candidate`, type: 'candidate' },
          { text: `Search "${query}" in OKR`, type: 'okr' },
        ];
      } 
      else if (this.mode === 'community' && !this.isValidEmail(query)) {
        this.searchResult = [
          ...baseSuggestions,
          { text: `Search "${query}" in Member`, type: 'member' },
        ];
      }

      else if(this.isValidEmail(query)){
        if(await this.getSearchUser(query)){
        this.searchResult = [
          { text: `${this.employeeData.name}'s Zircly Profile`, type: 'profile' },
          { text: `Post By ${this.employeeData.name} (${this.employeeData.email})`, type: 'post' },
          { text: `Events By ${this.employeeData.name} (${this.employeeData.email})`, type: 'event' },
          { text: `Polls By ${this.employeeData.name} (${this.employeeData.email})`, type: 'poll' },
          { text: `OKR By  ${this.employeeData.name} (${this.employeeData.email})`, type: 'okr' },
        ];
        }
      }
    },

getSearchUser(query) {
  return axios.get(`/search-user`, {
    params: {
      email: query
    }
  })
  .then((res) => {
    if (res.data.success) {
      this.employeeData = res.data.user
      return true;
    } else {
      return false;
    }
  })
  .catch((error) => {
    return false;
  });
    },

    
    suggestionSelected(suggestion){
        this.$router.push({ query: { post_hash_id: suggestion.hashid } });
    },
    CloseModel() {

      let currentURL = this.$route.fullPath;
      if(this.$route.path != currentURL){
        this.$router.push({ path: this.$route.path });
      }

    // Clear the search query and emit events
    // Clear the search query and emit events
      this.searchQuery = '';
      this.show = false;
      this.searchResult = [];

      
      bus.$emit("clearGlobalSearch");
  },
//User login and logout
checkin() {
      this.$bvModal.hide('swipe-in');
      this.swipeData.type = "In";
      const isMobile = /iPhone|iPad|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      const deviceType = isMobile ? 'Mobile Device' : 'Desktop or Tablet';
      this.swipeData.browser_agent = navigator.userAgent;
      this.swipeData.device_type = deviceType;
      axios.post("/swipe/add", this.swipeData).then((response) => {
        this.userData.last_swipe_type = "In";
        this.$cookies.set(
          "userData",
          this.userData,
          60 * process.env.VUE_APP_LOGIN_TIMEOUT
        );
        this.$nextTick(() => {
          this.$bvModal.hide("modal-1");
        });
        bus.$emit('user-swipe',true)


        this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Successfully Checked In',
          icon: 'CheckIcon',
          variant: 'success',
          hideClose: true,
        },
      }, {
        timeout: 2000, 
        position: 'bottom-center',
        toastClassName:"successful-info",
        hideProgressBar : true,
      });

      });
    },
    checkout() {

      this.$bvModal.hide('swipe-out');
      const isMobile = /iPhone|iPad|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      const deviceType = isMobile ? 'Mobile Device' : 'Desktop or Tablet';
      axios
        .post("/swipe/add", {
          type: "Out",
          user_feeling: this.swipeData.user_feeling,
          browser_agent: navigator.userAgent,
          device_type: deviceType,
        })
        .then((response) => {
          
          this.userData.last_swipe_type = "Out";
          this.$cookies.set(
            "userData",
            this.userData,
            60 * process.env.VUE_APP_LOGIN_TIMEOUT
          );
          this.$nextTick(() => {
            this.$bvModal.hide("modal-2");
          });
           bus.$emit('user-swipe',true)


           console.log(response);
           

          let [hours, minutes] = response?.data?.total_work_hours?.split(":");

           let isLessThanEightHours = parseInt(hours) < 8

            
       this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Successfully Checked Out',
          icon:'CheckIcon',
          variant : 'success',
          hideClose: true,
        },
      },
      {
        timeout : 2000,
        position : 'bottom-center',
        toastClassName:"successful-info",
        hideProgressBar : true,
      })


setTimeout(() => {
  if (isLessThanEightHours) {

    this.$toast({
        component: ToastificationContent,
        props: {
          title: `Your Total Worked Hours is ${hours} Hours ${minutes} minutes`,
          icon:'BellIcon',
          variant : 'danger',
          hideClose: true,
          
        },
      },
      {
        timeout : 3000,
        position : 'bottom-center',
        toastClassName:"error-info",
        hideProgressBar : true,
      })
 
  } else {
    this.$toast({
        component: ToastificationContent,
        props: {
          title: `Your Total Worked Hours is ${hours} Hours ${minutes} minutes`,
          icon:'CheckIcon',
          variant : 'success',
          hideClose: true,

        },
      },
      {
        timeout : 2000,
        position : 'bottom-center',
        toastClassName:"successful-info",
        hideProgressBar : true,

      })
  }
}, 3000); 
  });
    },
  },
};
</script>
<style>
  .myclass > div {
    position:absolute !important;
    top: 7% !important;
    left:28%!important;
    width: 100%;
  }
  
  .myclass > .modal-dialog > .modal-content {
  }
  .suggestions-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  background-color: white;
  width: 100%;
  z-index: 1000;
}

.suggestions-list li {
  padding: 10px;
  cursor: pointer;
}

.suggestions-list li:hover {
  background-color: #f0f0f0;
}
/* .Vue-Toastification__progress-bar{
  display: none !important;
} */
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.notification)?_c('b-list-group-item',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:(_vm.notification.link ? 'Open in new tab':''),expression:"notification.link ? 'Open in new tab':''"}],class:[
        'py-1',
        'notification-list',
        'cursor-pointer',
        _vm.isUnread ? 'seen-data' : 'unread-data' ],on:{"click":_vm.handleClick}},[_c('div',{staticClass:"d-flex align-items-center gap-10"},[(!_vm.isUnread)?_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"6","height":"6","viewBox":"0 0 6 6","fill":"none"}},[_c('circle',{attrs:{"cx":"3","cy":"3","r":"3","fill":"#38B22D"}})]):_vm._e(),_c('b-avatar',{attrs:{"size":"34","src":_vm.getAvatar()}}),_c('div',{staticClass:"post-action"},[_c('span',{staticClass:"font-weight-bold",domProps:{"innerHTML":_vm._s(_vm.notification.message)}}),_vm._v(" "+_vm._s(_vm.notification.additional_data ? (": " + (_vm.notification.additional_data.title)) : "")+" ")])],1),(_vm.notification.additional_data && !_vm.isUnread)?_c('b-card',{staticClass:"shadow-none rounded post-info mb-0 mt-50 ml-1"},[_c('div',{staticClass:"d-flex align-items-center gap-6 inner-content"},[(_vm.notification.additional_data.collage_img && _vm.notification.additional_data.collage_img.length > 0)?_c('div',{staticClass:"post-icon"},[(
                        _vm.notification.additional_data &&
                        _vm.notification.additional_data.collage_img &&
                        _vm.notification.additional_data.collage_img.length > 0
                    )?_c('b-img',{attrs:{"src":_vm.notification.additional_data.collage_img[0].link,"alt":"whats-new","width":"100%","height":"100%"}}):_vm._e()],1):_vm._e(),(_vm.notification.additional_data.collage_img && _vm.notification.additional_data.collage_img.length > 0)?_c('div',{staticClass:"post-description py-75"},[_c('h6',{staticClass:"mb-25"},[_vm._v(" "+_vm._s(_vm.notification.additional_data.title || "")+" ")]),_c('p',{staticClass:"mb-0",domProps:{"innerHTML":_vm._s(_vm.removeImgTag(_vm.notification.additional_data.body) || '')}})]):(_vm.notification.additional_data && !_vm.notification.additional_data.collage_img.length)?_c('div',{staticClass:"post-description py-75"},[_c('p',{staticClass:"mb-0 px-75",domProps:{"innerHTML":_vm._s(_vm.removeImgTag(_vm.notification.additional_data.body) ||'')}})]):_vm._e()])]):_vm._e(),(
            !_vm.isUnread &&
            _vm.notification.additional_data &&
            _vm.notification.additional_data.channel_type == 'post'
        )?_c('b-button',{staticClass:"mb-50 mt-75 wish-btn ml-1",attrs:{"variant":"outline-white","pill":""},on:{"click":function($event){$event.stopPropagation();return _vm.openWishCard(_vm.notification)}}},[_vm._v("Send Your Wish")]):_vm._e(),_c('p',{staticClass:"mb-0 mt-75 ml-1"},[_c('span',[_vm._v(_vm._s(_vm.notification.created_at + " ago"))])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <b-list-group-item

        :class="[
            'py-1',
            'notification-list',
            'cursor-pointer',
            isUnread ? 'seen-data' : 'unread-data',
        ]"
        @click="handleClick"
         v-b-tooltip="notification.link ? 'Open in new tab':''"
        v-if="notification"
    >
        <div
            class="d-flex align-items-center gap-10"
        >
            <svg
                v-if="!isUnread"
                xmlns="http://www.w3.org/2000/svg"
                width="6"
                height="6"
                viewBox="0 0 6 6"
                fill="none"
            >
                <circle cx="3" cy="3" r="3" fill="#38B22D" />
            </svg>
            <b-avatar size="34" :src="getAvatar()" />

            <div class="post-action">
                <span
                    class="font-weight-bold"
                    v-html="notification.message"
                ></span>
                {{
                    notification.additional_data
                        ? `: ${notification.additional_data.title}`
                        : ""
                }}
            </div>
            <!-- <div class="post-action">
                <span class="font-weight-bold" v-html="notification.message"></span>
                {{ notification.additional_data ? `: ${notification.additional_data.title}` : "" }}
           </div> -->
        </div>
        <b-card
            v-if="notification.additional_data && !isUnread"
            class="shadow-none rounded post-info mb-0 mt-50 ml-1"
        >
            <div class="d-flex align-items-center gap-6 inner-content">
                <div class="post-icon" v-if="notification.additional_data.collage_img && notification.additional_data.collage_img.length > 0">
                    <b-img
                        v-if="
                            notification.additional_data &&
                            notification.additional_data.collage_img &&
                            notification.additional_data.collage_img.length > 0
                        "
                        :src="notification.additional_data.collage_img[0].link"
                        alt="whats-new"
                        width="100%"
                        height="100%"
                    />
                </div>

                <div class="post-description py-75" v-if="notification.additional_data.collage_img && notification.additional_data.collage_img.length > 0">
                    <h6 class="mb-25">
                        {{ notification.additional_data.title || "" }}
                    </h6>
                    <p
                        class="mb-0"
                        v-html="removeImgTag(notification.additional_data.body) || ''"
                    ></p>
                </div>

                <div class="post-description py-75" v-else-if="notification.additional_data && !notification.additional_data.collage_img.length">
                    <p class="mb-0 px-75" v-html="removeImgTag(notification.additional_data.body) ||''"></p>
                </div>
            </div>
        </b-card>

        <!-- <b-card class="shadow-none rounded post-info mb-0 mt-50 ml-1" v-if="notification.additional_data  && !notification.additional_data.collage_img.length  && !isUnread">
                <div class="d-flex align-items-center gap-6 inner-content">
                  <div class="post-description py-75">
                    <p class="mb-0 px-75"  v-html="
                            removeImgTag(notification.additional_data.body) ||
                            ''
                        "></p>
                  </div>
                </div>
              </b-card> -->
        <b-button
            v-if="
                !isUnread &&
                notification.additional_data &&
                notification.additional_data.channel_type == 'post'
            "
            @click.stop="openWishCard(notification)"
            variant="outline-white"
            class="mb-50 mt-75 wish-btn ml-1"
            pill
            >Send Your Wish</b-button
        >
        <p class="mb-0 mt-75 ml-1">
            <span>{{ notification.created_at + " ago" }}</span>
        </p>
    </b-list-group-item>
</template>

<script>
import { bus } from "@/main.js";
import axios from "@axios";

export default {
    props: {
        notification: Object,
        userData: Object,
        isUnread: Number,
    },
    methods: {
        removeImgTag(content) {
            return content.replace(/<img[^>]*>/g, "");
        },
        openWishCard(data) {
            bus.$emit("view-wish", data);
        },
        getAvatar() {
            return this.notification.from_user
                ? this.notification.from_user.avatar
                : 'http://localhost:8000/assets/images/dummy-avatar.png';
        },
        handleClick() {
            this.$emit("markAsRead", this.notification);
        },
    },
};
</script>
